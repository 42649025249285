<template>
  <v-navigation-drawer
    :value="isAddNewSidebarActive"
    :permanent="isAddNewSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">文件上传</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-select
            v-model="submitData.channelId"
            :rules="[validators.required]"
            :items="channelList"
            outlined
            dense
            clearable
            label="频道名"
            placeholder="频道名"
            hide-details="auto"
            item-text="title"
            item-value="pageId"
            no-data-text="暂无频道"
            class="mb-6"
          ></v-select>

          <file-input-multi
            ref="fileInputMul"
            :file-input-model.sync="submitData.files"
            :accept-type="allSuffix.map(item => `.${item}`).join()"
            title="文件上传"
            class="mb-4"
          ></file-input-multi>

          <div class="d-flex">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
            >
              提交
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetData"
            >
              取消
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { ref } from '@vue/composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { modalFail, toastFail, toastSuccess } from '@core/utils/prompt'
import FileInputMulti from '@/views/components/file-input/FileInputMulti.vue'
import store from '@/store'
import storeModule from './storeModule'

export default {
  components: {
    FileInputMulti,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    channelList: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'file-list'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_ATTR_STORE_MODULE_NAME)) store.unregisterModule(POINT_ATTR_STORE_MODULE_NAME)
    // })

    const blankData = {
      channelId: '',
      files: [],
    }

    const valid = ref(false)
    const form = ref(null)
    const fileInputMul = ref(null)
    const fileNameVideo = ref('')
    const fileDetailVideo = ref({})
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      resetForm()
      fileInputMul.value.clearFilesUploaded()
      submitData.value = JSON.parse(JSON.stringify(blankData))
      emit('update:is-add-new-sidebar-active', false)
    }

    const randomDec = () => Math.floor(Math.random() * 10)

    const videoSuffix = ['mov', 'mpeg-1', 'mpeg-2', 'mpeg4', 'mp4', 'mpg', 'avi', 'wmv', 'mpegps', 'flv', '3gpp', 'webm', 'dnxhr', 'prores', 'cineform']
    const thumbnailSuffix = ['jpg', 'jpeg', 'png']
    const subtitleSuffix = ['srt']
    const allSuffix = [...videoSuffix, ...thumbnailSuffix, ...subtitleSuffix]

    const onSubmit = () => {
      if (valid.value) {
        if (submitData.value.files.some(item => !allSuffix.includes(item.name.split('.').pop()))) {
          toastFail(`目前只能支持提交：${allSuffix.join(',')} 后缀名的文件！`)

          return
        }
        store.dispatch('file-list/batchUpload', {
          channelId: submitData.value.channelId,
          files: submitData.value.files,
          versionNumber: `${(new Date()).valueOf() * 100}${randomDec()}${randomDec()}`,
        }).then(() => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toastSuccess('提交成功！')
          resetData()
        }).catch(error => {
          modalFail(error.response.data.message)
          console.log(error.response)
        })
      } else {
        validate()
      }
    }

    return {
      onSubmit,
      validate,
      resetData,
      form,
      valid,
      allSuffix,
      submitData,
      fileInputMul,
      fileNameVideo,
      fileDetailVideo,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
