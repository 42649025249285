import { ref, watch } from '@vue/composition-api'
import { modalFail, toastFail } from '@core/utils/prompt'
import store from '@/store'

export default function useList() {
  const headers = [
    { text: '频道ID', value: 'channelId', sortable: false },
    { text: '频道名', value: 'channelName', sortable: false },
    { text: '文件名', value: 'fileName', sortable: false },
    { text: '上传人', value: 'nickname', sortable: false },
    { text: '上传时间', value: 'createdAt', sortable: false },
  ]
  const dataList = ref([])
  const totalLists = ref(0)
  const channelId = ref('')
  const channelName = ref('')
  const channelList = ref([])
  const fileName = ref('')
  const loading = ref(false)
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
    itemsPerPage: 10,
    page: 1,
  })

  const fetchList = () => {
    store.dispatch('file-list/fetchLists', {
      pageSize: options.value.itemsPerPage,
      page: options.value.page,

      // sortBy: options.value.sortBy[0],
      // sortDesc: options.value.sortDesc[0],
      channelId: channelId.value,
      channelName: channelName.value,
      fileName: fileName.value,
    })
      .then(response => {
        const { data, total } = response.data
        totalLists.value = total || data?.length
        dataList.value = data
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        toastFail(error)
        console.log(error)
      })
  }

  const fetchChannelList = () => {
    store.dispatch('file-list/fetchChannelList').then(res => {
      console.log(res)
      channelList.value = res.data.data || []
    }).catch(error => {
      modalFail(error.response.data.message)
      console.log(error.response)
    })
  }

  watch([options], () => {
    loading.value = true
    fetchList()
  })

  return {
    fetchList,
    totalLists,
    dataList,
    options,
    headers,
    loading,
    channelId,
    channelName,
    channelList,
    fileName,
    fetchChannelList,
  }
}
