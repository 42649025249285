<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <v-file-input
          v-model="file"
          name="file"
          :label="title"
          :disabled="isDisabled"
          :accept="acceptType"
          :rules="isRequired ? [fileInputModel.length ? true : '这是必选项'] : [!file ? true : '已选择文件，请先上传或清空文件']"
          dense
          outlined
          multiple
          show-size
          hide-details="auto"
          clearable
          :value="fileInputModel"
        >
        </v-file-input>
      </v-col>
      <v-col
        v-if="!isDisabled"
        cols="12"
        md="4"
      >
        <div class="d-flex justify-space-around">
          <v-btn
            v-if="!isShowProgressBar"
            color="success"
            class="me-2 mt-1"
            small
            @click="uploadFiles"
          >
            上传
          </v-btn>
          <v-btn
            v-if="isShowProgressBar && !isPaused"
            outlined
            small
            class="me-2 mt-1"
            @click="uploadPause"
          >
            暂停
          </v-btn>
          <v-btn
            v-if="isShowProgressBar && isPaused"
            outlined
            small
            class="me-2 mt-1"
            @click="uploadResume"
          >
            继续
          </v-btn>
          <v-btn
            v-if="isShowProgressBar"
            small
            class="me-4 mt-1"
            color="error"
            @click="uploadCancel"
          >
            取消
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="isShowProgressBar"
      :value="progress.value"
      height="20"
      striped
      class="mt-2"
    >
      {{ Math.ceil(progress.value) }}%
    </v-progress-linear>

    <v-btn
      v-if="filesUploaded.length !== 0"
      color="error"
      small
      class="mt-2 mx-2"
      @click="uploadFilesDelete"
    >
      删除所有上传文件
    </v-btn>

    <v-tooltip
      v-for="(fileItem, index) in filesUploaded"
      :key="index"
      top
    >
      <template #activator="{ on, attrs }">
        <v-chip
          text-color="primary"
          color="primary"
          class="mt-2 mx-2"
          v-bind="attrs"
          v-on="on"
          @click="uploadFileDelete(index)"
        >
          {{ fileItem.name || fileItem.key.split('/').pop() }}{{ fileItem.duration ? ` | ${Math.floor(fileItem.duration / 60)}分${Math.floor(fileItem.duration % 60)}秒` : '' }}
        </v-chip>
      </template>
      <span>点击删除该上传文件</span>
    </v-tooltip>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  modalEnquireTitle,
  modalFail,
  toastFail, toastInfo, toastSuccess,
} from '@core/utils/prompt'
import { required } from '@core/utils/validation'

// import {
//   fetchOSS, progress, fileUploadMulti,
//   fileUploadPause, fileUploadCancel,
//   alioss, list,
// } from '@core/utils/useAliOSS'
import { AliOSS } from '@core/utils/useAliOSS'

export default {
  name: 'FileInputMulti',
  model: {
    prop: 'fileInputModel',
    event: 'update:file-input-model',
  },
  props: {
    fileInputModel: {
      type: [Array],
      required: true,
    },
    acceptType: {
      type: String,
      required: false,
      default: '',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '请上传文件',
    },
  },
  methods: {
    clearFilesUploaded() {
      this.filesUploaded = []
    },
  },
  setup(props, context) {
    const aliOSS = new AliOSS()
    const file = ref(null)
    const isShowProgressBar = ref(false)
    const isPaused = ref(false)
    const isUploaded = ref(false)
    const filesUploaded = ref([])
    const userId = JSON.parse(localStorage.getItem('userData')).id

    const progress = computed(() => aliOSS.progressBar)

    aliOSS.fetchOSS()

    /* 获取文件前缀 */
    function getPrefixPath() {
      const date = new Date()

      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}/${userId}/${date.valueOf()}`
    }

    // 文件上传（覆盖同名文件）
    function filesUpload() {
      console.log(filesUploaded.value)
      if (file.value) {
        const data = file.value[0]

        // 获取视频或者音频时长
        const fileUrl = URL.createObjectURL(data)

        // 经测试，发现audio也可获取视频的时长
        const audioElement = new Audio(fileUrl)
        let duration

        // TODO:通过监听音视频元数据加载事件获取对应的时长，不过不确定是否会出现文件上传后还没获取到的情况
        audioElement.addEventListener('loadedmetadata', () => {
          duration = audioElement.duration
          console.log('duration:', duration)
        })
        console.log(data)
        const fileNameUpload = `${getPrefixPath()}_${data.name}`
        aliOSS.fileUploadMulti(fileNameUpload, data).then(response => {
          console.log(response)

          /* 判断文件是否上传成功(阿里云上是否有同名文件) */
          aliOSS.list(fileNameUpload).then(result => {
            console.log(result)
            if (result.objects.length) {
              /* 阿里云中存在同名文件（上传成功） */
              file.value.splice(0, 1)

              /* 若文件同名，则删除第一个文件（覆盖） */
              const fileExistIndex = filesUploaded.value.findIndex(item => item.name === data.name)
              if (fileExistIndex !== -1) {
                filesUploaded.value.splice(fileExistIndex, 1)
              }
              filesUploaded.value.push({
                name: data.name,
                videoKey: fileNameUpload,
                size: data.size,
                type: data.name.endsWith('.srt') ? 'srt' : data.type,
              })
              console.log(filesUploaded.value)
              context.emit('update:file-input-model', filesUploaded.value)
              toastSuccess(`${data.name} 文件上传已完成,还剩下 ${file.value.length} 个文件`)
              if (file.value.length === 0) {
                toastSuccess('上传已完成!')
                isUploaded.value = true
                isShowProgressBar.value = false

                return
              }
              filesUpload()
            } else {
              /* 在阿里云OSS中找不到对应文件，上传失败 */
              modalFail('文件上传失败，请重新上传！')
              isShowProgressBar.value = false
              isPaused.value = false
            }
          }).catch(e => {
            /* 查询阿里云OSS中对应文件失败，也认为上传失败 */
            console.log(e)
            modalFail('文件上传失败，请重新上传！')
            isShowProgressBar.value = false
            isPaused.value = false
          })
        }).catch(error => {
          console.log(error)

          // 取消上传
          if (error.name === 'cancel') return
          modalFail('上传失败，请重试!')
          isShowProgressBar.value = false
          isPaused.value = false
        })
      }
    }

    const uploadFiles = () => {
      if (!file.value || file.value.length === 0) {
        toastInfo('还未选择文件')

        return
      }

      if (props.acceptType && props.acceptType !== '*') {
        console.log(file.value)
        const acceptSuffixList = props.acceptType.split(',')
        console.log(acceptSuffixList)
        for (let i = 0; i < file.value.length; i += 1) {
          if (!acceptSuffixList.includes(`.${file.value[i].name.split('.').pop()}`)) {
            modalFail(`仅支持上传 ${props.acceptType} 后缀的文件！`)

            return
          }
        }
      }

      modalEnquireTitle('! 注意 ATTENTION !', '请不要🙅🏻‍在文件即将要上传完成时点击暂停，可能会导致上传出错。').then(result => {
        if (result.isConfirmed) {
          const filenameArr = file.value.reduce((acc, cur) => {
            acc.push(cur.name)

            return acc
          }, [])

          // 重名文件判断
          const fileExistList = filesUploaded.value.filter(item => filenameArr.includes(item.name)) || []
          console.log(fileExistList)
          if (fileExistList.length) {
            modalEnquireTitle('注意', `存在同名文件 ${fileExistList.map(i => i.name).join()} ，是否覆盖相关文件？`).then(answer => {
              if (answer.isConfirmed) {
                toastSuccess('开始上传')
                isShowProgressBar.value = true
                filesUpload()
              }
            })

            return
          }

          toastSuccess('开始上传')
          console.log(file.value)
          isShowProgressBar.value = true
          filesUpload()
        }
      })
    }

    const uploadPause = () => {
      try {
        // 暂停上传。
        aliOSS.fileUploadPause()
        toastInfo('暂停上传')
        isPaused.value = true
        console.log(filesUploaded.value)
      } catch (e) {
        toastInfo('暂停上传失败，请重试')
        console.log(e)
      }
    }

    const uploadResume = () => {
      toastInfo('继续上传')
      isPaused.value = false
      console.log(filesUploaded.value)
      filesUpload()
    }

    const uploadCancel = () => {
      // 取消上传。
      aliOSS.fileUploadCancel().then(res => {
        toastFail('取消上传')
        console.log(res)
        isShowProgressBar.value = false
        isPaused.value = false
      }).catch(error => {
        toastFail('取消上传失败，请重试')
        console.log(error)
      })
    }

    const uploadFilesDelete = () => {
      // modalEnquireTitle('确定删除所有上传文件？', '点击确定后所有上传文件都会被删除').then(result => {
      //   if (result.isConfirmed) {
      // filesDelete(filesUploaded.value.reduce((acc, cur) => {
      //   acc.push(cur.key)
      //   console.log(acc)
      //
      //   return acc
      // }, [])).then(response => {
      //   console.log(response)
      filesUploaded.value = []
      context.emit('update:file-input-model', filesUploaded.value)
      file.value = []
      toastSuccess('成功删除所有文件')
      isUploaded.value = false
      isShowProgressBar.value = false

      // }).catch(error => {
      //   console.log(error.response)
      //   modalFail('删除文件失败')
      // })
      // }
      // })
    }

    const uploadFileDelete = fileIndex => {
      // modalEnquireTitle('确定删除该上传文件？', '点击确定后该文件就会被删除').then(result => {
      //   if (result.isConfirmed) {
      // fileDelete(filesUploaded.value[fileIndex].key).then(response => {
      //   console.log(response)
      filesUploaded.value.splice(fileIndex, 1)
      context.emit('update:file-input-model', filesUploaded.value)
      console.log(filesUploaded.value)
      toastSuccess('成功删除文件')
      if (filesUploaded.value.length === 0) {
        isUploaded.value = false
        isShowProgressBar.value = false
      }

      // }).catch(error => {
      //   console.log(error)
      //   modalFail('删除失败')
      // })
      //   }
      // })
    }

    function consoleTest(...mes) {
      console.log(...mes)
    }

    return {
      file,
      filesUploaded,
      isShowProgressBar,
      isPaused,
      isUploaded,
      uploadFilesDelete,
      uploadFileDelete,
      uploadFiles,
      progress,
      uploadPause,
      uploadResume,
      uploadCancel,
      consoleTest,

      // validator
      validator: { required },
    }
  },
}
</script>

<style scoped>

</style>
